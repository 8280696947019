import React from "react";
import IMG1 from "./../../images/image1.png";
import IMG2 from "./../../images/image2.png";
import IMG3 from "./../../images/image3.png";
import IMG4 from "./../../images/image4.png";
import IMG5 from "./../../images/image5.png";
import IMG6 from "./../../images/image6.png";
import IMG7 from "./../../images/image7.png";
import IMG8 from "./../../images/image8.png";
import IMG10 from "./../../images/image10.png";
import IMG11 from "./../../images/image11.png";
function Trusted() {
  return (
    <div>
      <div className="max-w-7xl m-auto px-6 md:px-10 pb-24">
        <div className="flex w-full justify-center">
          <h1 className="md:text-4xl text-2xl text-[#454646] montserrat font-bold md:mb-28 text-center fonbo mb-12">
            Trusted by trusted businesses
          </h1>
        </div>
        <div className=" flex justify-center md:justify-between flex-wrap gap-x-4 gap-y-14 md:gap-y-20">
          <div>
            <img src={IMG1} alt="" className="w-full h-16 object-contain" />
          </div>
          <div>
            <img src={IMG2} alt="" className="w-full h-16 object-contain" />
          </div>
          <div>
            <img src={IMG3} alt="" className="w-full h-16 object-contain" />
          </div>
          <div>
            <img src={IMG4} alt="" className="w-full h-16 object-contain" />
          </div>
          <div>
            <img src={IMG5} alt="" className="w-full h-24 object-contain" />
          </div>
          <div>
            <img src={IMG6} alt="" className="w-full h-24 object-contain" />
          </div>
          <div>
            <img src={IMG7} alt="" className="w-full h-24 object-contain" />
          </div>
          <div>
            <img src={IMG8} alt="" className="w-full h-20 object-contain" />
          </div>
          <div>
            <img src={IMG10} alt="" className="w-full h-24 object-contain" />
          </div>
          <div>
            <img src={IMG11} alt="" className="w-full h-24 object-contain" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trusted;
